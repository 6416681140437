footer {
    /* position: relative;
    bottom: 0; */
    background-color: #000000;
    color: #fff;
    padding: 20px 0;
    text-align: center;
    width: 100%;
    /* margin-top: auto; */
    font-size: 14px;
}

footer .made {
    font-size: 12px;
    margin-bottom: .5rem;
    text-align: center;
    color:#fff;
}

footer .made a {
    color: #fff;
}

footer .links {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 14px;
}

footer .links a {
    margin: 0 10px;
    color: #fff;
    text-decoration: none;
}