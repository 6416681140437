.loading-splash {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999;
    top:0;
    left: 0;
    backdrop-filter: blur(20px);
    display: flex;
    justify-content: center;
    align-items: center;
}