.tela-1 { 
    width: 100%;
    min-height: 100vh;
    padding: 0 2rem;
}

.tela-2 { 
    width: 100%;
    min-height: 100vh;
    color: #fff;
    background-color: #20b157;
    padding: 0 2rem;
}

.rh-200 {
    width: 200px;
    margin: 3rem;
}

.rh-300 {
    width: 300px;
    margin: 2rem
}

.rh-300>img {
    width: 100px;
}

.img-rh-1 {
    width: 250px;
    position: absolute;
    bottom: 0;
    left: 20px;
}

.img-rh-2 {
    width: 250px;
    position: absolute;
    bottom: 0;
    right: 20px;
}

.img-rh-col {
    width: 200px;
    height: 200px;
}

@media screen and (max-width: 600px) {
    .primeira-section {
        margin-top: 7rem;
    }

    .tela-2 {
        padding: 0 1rem;
    }

    .tela-1 {
        padding: 0 1rem;
    }
}